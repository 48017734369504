<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'RoleList',
              }"
            >{{ '角色管理' }}
            </b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'RoleList',
              }"
            >{{ '角色列表' }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>新增角色</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{ organizationName }}新增角色
              </h4>

              <role-form :role="role" :validationErrors="validationErrors" ref="form"></role-form>
            </div>
            <div
              class="d-flex justify-content-center"
            >
              <b-button
                class="mr-3"
                :to="getRoleListLink()"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import roleApi from "../../../apis/role";
import RoleForm from "./RoleForm"
import orgNameMixin from "@/mixins/organizationName"

export default {
  mixins: [orgNameMixin],
  components: { RoleForm },
  data() {
    return {
      showLoading: false,
      validationErrors: null,
      role: {
        name: null,
        code: null,
        level: null,
        description: null,
      },
      organizationName: this.$store.state.general.organization.name,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.$route.params.orgId || this.organization.id;
    }
  },
  methods: {
    getRoleListLink() {
      if (this.$route.name == 'OrgRoleCreate') {
        return { name: 'OrgRoleList', params: { orgId: this.orgId }, }
      }
      return { name: 'RoleList' }
    },
    async handleSubmit() {
      const result = await this.$refs.form.validate();
      if (! result) {
        return;
      }

      try {
          let response = await roleApi.storeRole(this.orgId, this.role);
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });
            this.$router.push(this.getRoleListLink());
          }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: "新增失敗",
            type: "error",
          });
        }
      }
    },
  },
};
</script>
